.text-container {
    display: flex;
    justify-content: center;
}

.text-error {
    border: 1px solid red;
    background: white;
    font-size: 15px;
    padding: 10px 15px;
    border-radius: 6px;
    margin-bottom: 1rem;
}
